import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Button,
  DropdownButton,
  Form,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../../services/service";
import Loader from "../../../../components/Loader";
import empty from "is-empty";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import "../../.././common.css";
import CustomModal from "../../../../components/CustomModal";

const EqualsApiLogForm = () => {

  
  let navigate = useNavigate();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;


  let location = useLocation();

  let propsState = location.state;
  const [country, setCountry] = useState([]);
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Equals API Log",
      href: "/admin/logs/equalsapilog",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Equals API Log Details",
      id: "details",
      show: true,
    },
  ]);

  const [user, setUser] = useState([]);
  const [getcustomer, setGetCustomer] = useState([]);
  
  const handleInput = (keyName, value) => {
    console.log(keyName,value,"KJBVYGUVBI")
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    return navigate("/admin/masters/customer");
  };

  const findErrors = () => {
    const { scheme_id, scheme_name, country_id, min_amount, max_amount } = form;
    const newErrors = {};

    if (empty(scheme_id)) newErrors.scheme_id = "This is a required field";
    if (empty(scheme_name)) newErrors.scheme_name = "This is a required field";
    if (empty(country_id)) newErrors.country_id = "This is a required field";
    if (empty(min_amount)) newErrors.min_amount = "This is a required field";
    if (empty(max_amount)) newErrors.max_amount = "This is a required field";
    return newErrors;
  };

  // const saveForm = () => {
  //   let id = editMode ? propsState.id : null,
  //     method = editMode ? "put" : "post";
  //   console.log(editMode, "mode");
  //   const newErrors = findErrors();
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     toast.error("Enter all required details to proceed", toastAttr);
  //   } else {
  //     setLoader(true);
  //     let url = editMode
  //         ? service.scheme_create + form.id + "/update/"
  //         : service.scheme_create,
  //       data = JSON.parse(JSON.stringify(form));
  //     console.log("-----------");
  //     axios({
  //       url: url,
  //       method: method,
  //       headers: tokenKey,
  //       data: data,
  //     })
  //       .then((res) => {
  //         if (editMode) setForm(res.data);
  //         toast.success("Saved successfully", toastAttr);
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 401) window.location.href = "/";
  //         else toast.error("Area already exists", toastAttr);
  //         setLoader(false);
  //       });
  //   }
  // };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    if (country.length == 0) {
      axios({
        url: service.country_list,
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setCountry(res.data.results);
        })
        .catch((err) => {
          if(err.response.status==400){
            setShow(true);
            setBody(err.response.data.message);
          }else if(err.response.status==500){
            setShow(true);
            setBody(err.response.data);
          }else if(err.response.status==401){
            setShow(true);
            setBody(err.response.data.detail);
          }
        });
    }
    
    if (user.length == 0) {
      axios({
        url: service.customer_list,
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setUser(res.data.results);
        })
        .catch((err) => {
          if(err.response.status==400){
            setShow(true);
            setBody(err.response.data.message);
          }else if(err.response.status==500){
            setShow(true);
            setBody(err.response.data);
          }else if(err.response.status==401){
            setShow(true);
            setBody(err.response.data.detail);
          }
        });
    }

    console.log(propsState);
    if (propsState && propsState.editMode) {
      axios({
        url: service.equalsapi_log+propsState.id+"/",
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setForm(res.data);
          console.log(res);
          setLoader(false);
          console.log(form);
        })
        .catch((err) => {
          if(err.response.status==400){
            setShow(true);
            setBody(err.response.data.message);
          }else if(err.response.status==500){
            setShow(true);
            setBody(err.response.data);
          }else if(err.response.status==401){
            setShow(true);
            setBody(err.response.data.detail);
          }
        });
      }
  }, []);


  return (
    <Loader loading={loading} overlay={overlay}>

    <Container>
      <Row >
        <Col md={12}>
          <Card className="admin__list">
            <CardBody className="ms-3">
            <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Equals Api Log Details</h3>
                </Col>
                
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}>
                  
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <div>
                    <Form>
                      
                        {tab.details ? (
                          <>
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      API
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="api_name"
                                      className="input-border"
                                      value={form.api_name}
                                    />
                                  </Col>
                                </Row>
                                
                              </Col>

                            </Row>
                            
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Card Number
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="card_number"
                                      className="input-border"
                                      value={form.card_number}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      OTP
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      type="text"
                                      name="otp"
                                      className="input-border"
                                      value={form.otp}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Mobile Number
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="mobile"
                                      className="input-border"
                                      value={form.mobile}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    OTP Verified
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <input readOnly type="checkbox" checked={form.otp_verified}/>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>


                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Token ID
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="token_id"
                                      className="input-border"
                                      value={form.token_id}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    Status
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      type="text"
                                      name="status"
                                      className="input-border"
                                      value={form.status}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    Request Time
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="request_time"
                                      className="input-border"
                                      value={form.request_time}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    Country Code
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Select
                                      disabled
                                      type="text"
                                      name="country_id"
                                      className="input-border"
                                      value={form.country_id}>

                                        {country.map((cou) => (
                                          <option
                                            key={cou.id}
                                            value={cou.id}
                                            className="dropdown"
                                          >
                                            {cou.country_code}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    Response Time
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="response_time"
                                      className="input-border"
                                      value={form.response_time}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    Country Name
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      type="text"
                                      name="country_name"
                                      className="input-border"
                                      value={form.country_name}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={12} className="d-grid gap-3">
                                <Row>
                                  <Col md={2}>
                                    <Form.Label className="mt-2">
                                    Request
                                    </Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <Form.Control
                                      readOnly
                                      as="textarea"
                                      name="request_params"
                                      className="input-border"
                                      value={form.request_params}
                                      style={{height:'170px'}}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>


                            <Row className="py-3">

                              <Col md={12} className="d-grid gap-3">
                                <Row>
                                  <Col md={2}>
                                    <Form.Label className="mt-2">
                                    Response
                                    </Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <Form.Control
                                      readOnly
                                      as="textarea"
                                      name="response_params"
                                      className="input-border"
                                      value={form.response_params}
                                      style={{height:'170px'}}
                                    />

                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                          </>
                        ) : (
                          ""
                        )}
                     <CustomModal show={show} body={Body} handleClose={handleClose} />
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </Loader>
    
  );
};

export default EqualsApiLogForm;
