import React,{useState,useEffect} from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const Breadcrumbs = (props) => {
  let navigate = useNavigate();
  let length = props.data.length;
    const redirect = (path,state) => {
        if(state) 
            return navigate({
                pathname:path,
                state : state
            });
        else return navigate(path)
    }
    return (
        <Breadcrumb>
            {props.data && props.data.map((obj,index) => {
                if(length === 1)
                    return <Breadcrumb.Item className='singleNav'>{obj.title}</Breadcrumb.Item>
                else if(index === (length -1))
                    return <Breadcrumb.Item active>{obj.title}</Breadcrumb.Item>
                else return <Breadcrumb.Item onClick={e=>{redirect(obj.href,obj.state)}}>{obj.title}</Breadcrumb.Item>
            })}
        </Breadcrumb> 
    )
}

export default Breadcrumbs;