import "./App.css";
import { Routes, Route, Outlet } from "react-router-dom";
import Login from "./pages/Login";
import Country from "./pages/admin/masters/Country";
import Dashboard from "./pages/admin";
import Master from "./pages/admin/masters";
import Log from "./pages/admin/logs";
import CountryForm from "./pages/admin/masters/Country/form";
import Scheme from "./pages/admin/masters/Scheme";
import SchemeForm from "./pages/admin/masters/Scheme/form";
import { useDispatch } from "react-redux";
import { authDetails } from "./actions/authActions";
import { Suspense, lazy, useEffect } from "react";
import Customer from "./pages/admin/masters/Customer";
import CustomerForm from "./pages/admin/masters/Customer/form";
import ApiLog from "./pages/admin/logs/Apilog";
import ApiLogForm from "./pages/admin/logs/Apilog/form";
import SiCollectionLog from "./pages/admin/logs/SiCollectionLog";
import SiCollectionLogForm from "./pages/admin/logs/SiCollectionLog/form";
import PrivateRoutes from "./PrivateRoutes";
import "./pages/common.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import Sidebar from "./components/Sidebar";

import EqualsApiLog from "./pages/admin/logs/EqualsApiLog";
import EqualsApiLogForm from "./pages/admin/logs/EqualsApiLog/form";
import TermsAndCondition from "./components/termsandcondition";
import PaymentLog from "./pages/admin/logs/PaymentLog";
import PaymentLogForm from "./pages/admin/logs/PaymentLog/form";
import AdminLogin from "./pages/AdminLogin";
import EMandateRegister from "./pages/customer/EMandateRegister";
import ProceedRazorpay from "./pages/customer/ProceedRazorpay";
import ResponseBilldesk from "./pages/customer/ResponseBilldesk";
import GenerateSettlement from "./pages/admin/logs/GenerateSettlement";

const SchemeRegistered = lazy(() =>
  import("./pages/customer/SchemeRegistered")
);
const GiftCardTopup = lazy(() => import("./pages/customer/GiftCardTopup"));
const GiftCard = lazy(() => import("./pages/customer/GiftCard"));
const ProceedBilldesk = lazy(() => import("./pages/customer/ProceedBilldesk"));
const ResponseCCAvenue = lazy(() =>
  import("./pages/customer/ResponseCCAvenue")
);

const PaymentHistory = lazy(() => import("./pages/customer/PaymentHistory"));

function App() {
  let dispatch = useDispatch();
  let tokenKey = sessionStorage.getItem("tokenkey") ? true : false;

  const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((icon) => Icons[icon]);

  library.add(...iconList);

  useEffect(() => {
    if (tokenKey) {
      dispatch(authDetails());
    }
  }, [tokenKey]);
  return (
    <div>
      <Suspense fallback={<div className="container">Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/responsebilldesk" element={<ResponseBilldesk />} />
          {/* <Route element={<PrivateRoutes allowedRoles={"customer"} />}> */}
            <Route path="/" element={<PageLayout />}>
              <Route exact path="/giftcard" element={<GiftCard />} />
              <Route
                exact
                path="/schemeregistered"
                element={<SchemeRegistered />}
              />
              <Route path="/giftcardtopup" element={<GiftCardTopup />} />
              <Route path="/emandateregister" element={<EMandateRegister />} />

              {/* Payment Gateway Request and Response routes */}
              <Route path="/proceedBilldesk" element={<ProceedBilldesk />} />
              <Route path="/proceedRazorpay" element={<ProceedRazorpay />} />

              <Route path="/paymenthistory" element={<PaymentHistory />} />
            {/* </Route> */}
          {/* </Route> */}
          {/* <Route element={<PrivateRoutes allowedRoles={"internal"} />}> */}
            {/* <Route path="/" element={<PageLayout />}> */}
              <Route path="/admin/dashboard" element={<Dashboard />} />

              <Route path="/admin/masters" element={<Master />} />
              <Route path="/admin/masters/country" element={<Country />} />
              <Route
                path="/admin/masters/countryForm"
                element={<CountryForm />}
              />
              <Route path="/admin/masters/scheme" element={<Scheme />} />
              <Route
                path="/admin/masters/schemeForm"
                element={<SchemeForm />}
              />
              <Route path="/admin/masters/customer" element={<Customer />} />
              <Route
                path="/admin/masters/customerForm"
                element={<CustomerForm />}
              />

              <Route path="/admin/logs" element={<Log />} />
              <Route path="/admin/logs/apilog" element={<ApiLog />} />
              <Route path="/admin/logs/apilogForm" element={<ApiLogForm />} />
              <Route path="/admin/logs/paymentlog" element={<PaymentLog />} />
              <Route path="/admin/logs/generatesettlement" element={<GenerateSettlement />} />
              <Route
                path="/admin/logs/paymentlogForm"
                element={<PaymentLogForm />}
              />
              <Route
                path="/admin/logs/sicollectionlog"
                element={<SiCollectionLog />}
              />
              <Route
                path="/admin/logs/sicollectionlogForm"
                element={<SiCollectionLogForm />}
              />
              <Route
                path="/admin/logs/equalsapilog"
                element={<EqualsApiLog />}
              />
              <Route
                path="/admin/logs/equalsapilogForm"
                element={<EqualsApiLogForm />}
              />
            </Route>
          {/* </Route> */}
            <Route path="/termsandcondition" element={<TermsAndCondition />} />
        </Routes>
      </Suspense>
      {/* <Footer /> */}
    </div>
  );
}

export default App;

const PageLayout = () => (
  <div>
    <Sidebar />
    <div style={{ padding: "3%" }}>
      <Outlet />
    </div>
  </div>
);
