import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";

import { GiMoneyStack } from "react-icons/gi";
import { useNavigate, useLocation } from "react-router-dom";
// import Search from '../../../Helper/CustomSearch'
import axios from "axios";
import { AiFillBackward } from "react-icons/ai";

import { toast } from "react-toastify";
import service from "../../services/service";
import Loader from "../../components/Loader";
import empty from "is-empty";

import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import CustomModal from "../../components/CustomModal";
import "./SchemeRegistered.css";
import { clearAllCookies } from "../../actions/authActions";

const EMandateRegister = () => {
  let navigate = useNavigate();

  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;
  console.log("gitftcard topup");

  let location = useLocation();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let dispatch = useDispatch();

  // Custom const
  const [modeofpay, setModeofpay] = useState([]);
  const [paymentgateway, setPaymentgateway] = useState([]);
  const [paymentgatewayurl, setPaymentgatewayurl] = useState([]);

  const authDetail = useSelector((state) => state.authDetail);
  const { loading: authloading, error, details } = authDetail;

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [tab] = useState({
    details: true,
  });

  const handleInput = (keyName, value) => {
    console.log(keyName, value, "KJBVYGUVBI");
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    console.log(form);
    const { modeofpay, paymentgateway } = form;
    const newErrors = {};

    if (empty(modeofpay)) newErrors.modeofpay = "This is a required field";
    if (empty(paymentgateway))
      newErrors.paymentgateway = "This is a required field";
    return newErrors;
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  // Load Default data by calling EMandateRegister
  useEffect(() => {
    setLoader(true);
    let giftcard_topup_id = location.state ? location.state.EnrollmentID : null;
    console.log(giftcard_topup_id, "giftcard_topup_id");
    if (giftcard_topup_id === null) {
      navigate("/schemeregistered", { state: {} });
    }

    let url = service.e_mandate_register;
    let data = { giftcard_topup_id: giftcard_topup_id };

    axios({
      url: url,
      method: "post",
      headers: { Authorization: tokenKey },
      data: data,
    })
      .then((res) => {
        console.log(res);
        // let res_data = res.data[0].Response.personalDetails;
        setPaymentgateway(res.data[0].paymentgateway);
        setModeofpay(res.data[0].cards);
        setPaymentgatewayurl(res.data[0].paymentgateway_details);
        // res_data["PendingAmount"] =
        //   res.data[0].Response.personalDetails.TotalAmount -
        //   res.data[0].Response.personalDetails.AmountPaid;
        setForm(res.data[0]);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "catchhhhhhhhhhhhhhhhh");
        // toast.error(err + " - No record found!", toastAttr);
        setLoader(false);

        if (err?.status === 401) {
          window.location.href = "/";
        }
        console.log(err, "errrrrrrrrrrrrr");
        if (err.response.status === 500) {
          setShow(true);
          setBody(err.response.data);
        } else if (err.response.status === 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else {
          setShow(true);
          setBody(err.response);
        }
      });
  }, []);

  // Form Submit for Topup Page
  const saveForm = () => {
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let data = JSON.parse(
        JSON.stringify({
          first_name: form.FirstName,
          last_name: form.LastName,
          mobile_no: form.MobileNumber,
          amount: form.EMIAmount,
          account_no: form.EnrollmentID,
        })
      );
      let url = "";
      let nav_url = "";
      if (form["paymentgateway"] === "hdfc") {
        url = service.proceed_hdfc;
      } else if (form["paymentgateway"] === "billdesk") {
        url = service.proceed_billdesk;
        nav_url = "/proceedBilldesk";
      }
      // if (data["paymentgateway"] === "cc_avenue") {
      //   url = service.proceed_ccavenue;
      //   data["giftcard_topup_id"] = data["EnrollmentNo"];
      //   nav_url = "/proceedccavenue";
      // } else if (data["paymentgateway"] === "si_on_cc") {
      //   url = service.proceed_ccavenue;
      //   data["giftcard_topup_id"] = data["EnrollmentNo"];
      //   nav_url = "/proceedccavenue";
      // } else if (data["paymentgateway"] === "dohabank") {
      //   url = service.proceed_dohabank;
      //   data["giftcard_topup_id"] = data["EnrollmentNo"];
      //   nav_url = "/proceeddohabank";
      // } else if (data["paymentgateway"] === "smart_pay") {
      //   url = service.proceed_smartpay;
      //   data["giftcard_topup_id"] = data["EnrollmentNo"];
      //   nav_url = "/proceedsmartpay";
      // } else if (data["paymentgateway"] === "knet") {
      //   url = service.proceed_knet;
      //   data["giftcard_topup_id"] = data["EnrollmentNo"];
      //   nav_url = "/proceedknet";
      // }

      // API Call
      axios({
        url: url,
        method: "post",
        headers: { Authorization: tokenKey },
        data: data,
      })
        .then((res) => {
          console.log(res, "res");
          if (res.status === 200) {
            setLoader(false);
            if (form["paymentgateway"] === "hdfc") {
              window.location.replace(res.data.pgurl);
            } else {
              navigate(nav_url, {
                state: {
                  returndata: res.data,
                },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          if (err.response.status === 401) {
            window.location.href = "/";
          } else if (err.response.status === 500) {
            setShow(true);
            setBody(err.response.data);
          } else if (err.response.status === 400) {
            setShow(true);
            setBody(err.response.data.message);
          } else {
            setShow(true);
            setBody(err.response);
          }
        });
    }
  };

  return (
    <Loader loading={loading} overlay={overlay}>
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <Card className="gift__card__topup__card">
                <CardBody className="ms-3">
                  <Row>
                    <Col md={12}>
                      {}
                      <div>
                        <Form>
                          {tab.details ? (
                            <>
                              <Row className="d-flex justify-content-between align-content-center">
                                <Col
                                  md={10}
                                  className="fs-5 text-uppercase fw-bold text-primary"
                                >
                                  E Mandate Register
                                </Col>
                                <Col
                                  md={2}
                                  className="btn-grad-primary w-10 mx-4 web__view"
                                  onClick={() => navigate("/schemeregistered")}
                                >
                                  <AiFillBackward size={20} />
                                  back
                                </Col>
                              </Row>

                              <hr />
                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Enrollment ID
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.EnrollmentID}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Total Amount (
                                        {details?.country_id?.currency})
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.TotalInstallmentAmount}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Registered Date
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.JoinDate}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Monthly Reload Amount (
                                        {details?.country_id?.currency})
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.EMIAmount}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Paid Amount (
                                        {details?.country_id?.currency})
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.AmountPaid}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        {/* This field need to be added in Equals API */}
                                        No. of Paid Installments
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.NoOfPaidInstallment}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Pending Amount (
                                        {details?.country_id?.currency})
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.RemainingAmount}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Maturity Date
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.MaturityDate}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col> */}
                              </Row>

                              {/* Customer Info */}
                              <Row className="mb-3">
                                <p></p>
                                <div className="col-lg-5 text-uppercase fw-bold text-primary">
                                  Customer Information
                                </div>
                              </Row>
                              <hr />

                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Customer
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.FirstName}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Customer ID
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {/* This field need to be added in Equals API */}
                                        {form.CustomerID}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Mobile
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.MobileNumber}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={5}>
                                      <Form.Label className="mt-2">
                                        Email
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Label className="mt-2 text-secondary">
                                        {form.EmailId}
                                      </Form.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row></Row>

                              <span>
                                {form.si_opted_status && (
                                  <label className="text-danger mr-1">* </label>
                                )}
                                <label className="control-label mr-2">
                                  {form.si_opted_status && "Note : "}
                                </label>
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {form.si_opted_status &&
                                    "SI auto debit is enabled for this account."}
                                </span>
                                <br />
                                <br />
                              </span>

                              {/* PAYMENT INFORMATION */}
                              <Row className="mb-3">
                                <div className="col-lg-5 text-uppercase fw-bold text-primary">
                                  Payment Information
                                </div>
                              </Row>
                              <hr />
                              <Row className="py-1">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Mode of Pay
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Select
                                        name="modeofpay"
                                        className="form-control"
                                        placeholder="select ModeofPay"
                                        value={form.modeofpay}
                                        onChange={(e) =>
                                          handleInput(
                                            "modeofpay",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.modeofpay}
                                      >
                                        <option value="">
                                          Select the Mode Of Pay
                                        </option>
                                        {modeofpay.map((mop) => (
                                          <option
                                            key={mop.id}
                                            value={mop.id}
                                            className="dropdown"
                                          >
                                            {mop.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.modeofpay}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Payment Gateway
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Select
                                        name="paymentgateway"
                                        className="form-control"
                                        placeholder="select paymentgateway"
                                        value={form.paymentgateway}
                                        onChange={(e) =>
                                          handleInput(
                                            "paymentgateway",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.paymentgateway}
                                      >
                                        <option value="">
                                          Select the Payment Gateway
                                        </option>

                                        {paymentgateway.map((pg) =>
                                          pg.id === "si_on_cc" &&
                                          form.modeofpay === "debit_card" &&
                                          !form.si_opted_status &&
                                          form.EMI < 1501 ? null : (
                                            <option
                                              key={pg.id}
                                              value={pg.value}
                                              className="dropdown"
                                            >
                                              {pg.name}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.paymentgateway}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              {/* PAY Button */}
                              <Row className="my-3" align="right">
                                <Col md={8}></Col>
                                <Col md={3}>
                                  <Button
                                    className="btn-grad-primary"
                                    onClick={(e) => {
                                      saveForm();
                                    }}
                                  >
                                    <GiMoneyStack size={20} />
                                    &nbsp; Pay
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                          <CustomModal
                            show={show}
                            body={Body}
                            handleClose={handleClose}
                          />
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Loader>
  );
};

export default EMandateRegister;
