import React, { useState, useEffect, createRef } from "react";
import { Card, Form, Modal, Button, ModalHeader, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import empty from "is-empty";
import OtpInput from "react-otp-input";
import CloseButton from "react-bootstrap/CloseButton";

import "./common.css";
import service from "../services/service";
import Header from "../components/Header";
import { authDetails, clearAllCookies } from "../actions/authActions";
import CustomModal from "../components/CustomModal";
import Loader from "../components/Loader";
import { InputGroup } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { Input } from "reactstrap";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const kalyan_bg = `${process.env.PUBLIC_URL}/images/kalyan_bg.jpg`;
const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

function containsSpecialCharacters(str) {
  var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  return regex.test(str);
}

function Login() {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  const [loading1, setloading1] = useState(false);
  const [overlay1, setoverlay1] = useState(false);

  const authDetail = useSelector((state) => state.authDetail);
  const { loading: detailLoading, error, details } = authDetail;

  const [showModal, setShowModal] = useState(false);
  const [otp_, setOtp_] = useState("");

  const [otplist, setOtpList] = useState([null, null, null, null, null, null]);

  const [scheme_no, setSchemeNo] = useState("");
  const [mobile_no, setMobileNo] = useState("");

  const [showotp, setShowotp] = useState(true);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  let dispatch = useDispatch();

  const [otp_type, setOtpType] = useState("");

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const [copyValue, setCopyValue] = useState("");

  const [view, setView] = useState(false);

  const [isActive, setIsActive] = useState(false);

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(/=|;/);
      cookieValue = decodeURIComponent(cookies[1]);
    }
    return cookieValue;
  }
  var csrftoken = getCookie("csrf_token");
  var header = {};
  if (csrftoken != null) {
    header["X-CSRFToken"] = csrftoken;
  }

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const toggleModal = (close) => {
    // alert(close)
    if (close == "close") {
      setMobileNo("");
    }
    // document.getElementById('otp_field').focus()
    // if(showModal){
    //   document.getElementById('otp_field').focus()
    // }
    setShowModal(!showModal);
    setOtp_("");
  };

  const verifyOTP = (e) => {
    let data = {
      login_type: "mobile_no",
      mobile: form.mobile_no,
      otp_type: "verify_otp",
      otp: document.getElementById("otp").value,
    };
    setLoader1(true);
    axios({
      url: service.login,
      method: "post",
      data: data,
      headers: { ...header },
    })
      .then((res) => {
        sessionStorage.setItem("tokenkey", res.data.token);
        dispatch(authDetails());

        if (otp_type === "login_verify") {
          if (res.data.user_type === "internal") {
            toast.success("Verified successfully", toastAttr);
            setTimeout(() => {
              navigate("/admin/dashboard");
            }, 1000);
          } else if (res.data.user_type === "customer") {
            toast.success("Verified successfully", toastAttr);
            setTimeout(() => {
              navigate("/schemeregistered");
            }, 1000);
          }
        } else {
          setShowPasswordModal(true);
        }
        setMobileNo("");
        setShowModal(false);
        setLoader1(false);
      })
      .catch((err) => {
        if (err?.response?.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err?.response?.status == 500) {
          setShow(true);
          setBody((err.response.data));
        } else if (err?.response?.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader1(false);
      });
  };

  const ResendOTP = () => {
    setLoader1(true);
    axios({
      url: service.login,
      method: "post",
      data: {
        login_type: form.mobile_no ? "mobile_no" : null,
        mobile: form.mobile_no,
        otp_type: "resend_otp",
      },
      headers: { ...header },
    })
      .then((res) => {
        setShowotp(true);
        setLoader1(false);
      })
      .catch((err) => {
        if (err?.response?.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err?.response?.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err?.response?.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader1(false);
      });
  };

  const handleInput = (keyName, value) => {
    console.log(keyName, value);

    if (keyName === "scheme_no") {
      if (value.trim().length > 0) {
        form.scheme_no = value;
        form.mobile_no = "";
      }
    } else {
      //mobile_no
      if (value.trim().length > 0) {
        form.scheme_no = "";
        form.mobile_no = value;
      }
    }

    console.log(form, "form");

    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    const { scheme_no, mobile_no } = form;
    const newErrors = {};

    if (empty(scheme_no)) newErrors.scheme_no = "This is a required field";
    if (empty(mobile_no)) newErrors.mobile_no = "This is a required field";
    return newErrors;
  };

  function loginHandler(event) {
    event.preventDefault();
    const newErrors = findErrors();
    if (
      Object.keys(newErrors).length > 0 &&
      Object.keys(newErrors).length === 2
    ) {
      setErrors(newErrors);
      toast.error("Enter either Scheme or Mobile No. to proceed", toastAttr);
    } else {
      let _data = {};
      if (form.mobile_no.trim().length > 0) {
        _data = {
          login_type: form.mobile_no ? "mobile_no" : null,
          mobile: form.mobile_no,
          otp_type: "send_otp",
        };
      }else{
        _data = {
          login_type: form.scheme_no ? "scheme_no" : null,
          scheme_no: form.scheme_no
        };
      }
      setLoader(true);
      let url = service.login,
        data = JSON.parse(JSON.stringify(_data));

      axios({
        url: url,
        method: "post",
        data: data,
        headers: { ...header },
      })
        .then((res) => {
          if (res.status === 200) {
            if(data.login_type === "scheme_no"){
              setForm({
                mobile_no: res.data.mobile
              });
              // form.mobile_no = res.data.mobile
            }
            toggleModal();
            setLoader(false);
            setShowotp(true);
            // OTPInput()
            setOtpType("login_verify");
            return;
          }

          sessionStorage.setItem("tokenkey", res.data.token);
          dispatch(authDetails());

          if (res.data.user_type === "internal") {
            toast.success("Login successfully", toastAttr);
            setTimeout(() => {
              navigate("/admin/dashboard");
            }, 1000);
          } else if (res.data.user_type === "customer") {
            toast.success("Login successfully", toastAttr);
            setTimeout(() => {
              navigate("/schemeregistered");
            }, 1000);
          }
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 400) {
            setShow(true);
            setBody(err.response.data.message);
          } else if (err?.response?.status == 500) {
            setShow(true);
            setBody((err.response.data));
          } else if (err?.response?.status == 401) {
            setShow(true);
            setBody(err.response.data.detail);
          }
          setLoader(false);
        });
    }
  }
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };
  const setLoader1 = (val) => {
    setloading1(val);
    setoverlay1(val);
  };
  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const navigate = useNavigate();

  const signupHandler = () => {
    return navigate("/sign_up");
  };

  useEffect(() => {
    document.getElementById("scheme_no").focus();
    if (sessionStorage.getItem("tokenkey") && details) {
      if (details.user_type === "internal") {
        navigate("/admin/dashboard");
      } else if (details.user_type === "customer") {
        navigate("/schemeregistered");
      }
    }
  }, [details]);

  const forgotPassword = () => {
    toggleModal();
    setOtpType("forget_verify");
    setShowotp(false);
  };

  const EnterOTP = (props) => {
    if (showotp == true) {
      return (
        <>
          <div className="d-flex justify-content-center mt-5 fw-bold">
            Enter OTP
          </div>
          <div className="d-flex justify-content-center">
            <Col md={3}>
              <Input
                className="mt-3 form-control text-center"
                id="otp"
                maxLength={6}
                type="text"
              />
            </Col>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button className="btn btn-link" onClick={ResendOTP}>
              Resend OTP
            </button>
          </div>
        </>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-3 fw-bold">
          <button
            className="btn btn-success"
            onClick={ResendOTP}
            disabled={!mobile_no}
          >
            Send OTP
          </button>
        </div>
      );
    }
  };

  return (
    <Loader loading={loading} overlay={overlay}>
      <div
        className="login__signup__background"
        style={{ backgroundImage: `url(${kalyan_bg})` }}
      >
        <Card className="login__signup__card">
          <div className="d-flex justify-content-center my-3">
            <img src={kalyan_logo} className="kalyan__logo" alt="kalyan_logo" />
          </div>
          <Card.Body>
            <Form.Control
              type="text"
              id="scheme_no"
              className="mt-5 fs-5"
              placeholder="Scheme number"
              onChange={(e) => handleInput("scheme_no", e.target.value)}
              value={form.scheme_no || ""}
              isInvalid={!!errors.scheme_no}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("mobile_no").focus();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.scheme_no}
            </Form.Control.Feedback>

            <div className="hr-sect mt-4">Or</div>

            <Form.Control
              id="mobile_no"
              className="mt-4 fs-5"
              type="number"
              placeholder="Mobile number"
              onChange={(e) => handleInput("mobile_no", e.target.value)}
              value={form.mobile_no || ""}
              isInvalid={!!errors.mobile_no}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("login").focus();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobile_no}
            </Form.Control.Feedback>

            <button
              id="login"
              className="btn btn-primary form-control mt-4 fs-5 text-white"
              onClick={loginHandler}
            >
              Login
            </button>
          </Card.Body>
        </Card>
        <div>
          <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="modalotpheader">
              <div className="d-flex justify-content-between">
                <span className="fw-bold fs-5">Verify OTP</span>
              </div>
              <button
                className="btn btn-link"
                onClick={() => toggleModal("close")}
              >
                <CloseButton variant="white" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <Loader loading={loading1} overlay={overlay1}>
                <div className="row d-flex justify-content-center fw-bold">
                  Mobile
                </div>

                <div className="d-flex justify-content-center">
                  <Col md={3}>
                    <Form.Control
                      type="text"
                      className="form-control text-center mt-3 fs-6"
                      id="otp_field"
                      onChange={(e) => handleInput("scheme_no", e.target.value)}
                      value={form.mobile_no}
                      disabled={showotp}
                    />
                  </Col>
                </div>

                <EnterOTP />
              </Loader>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => toggleModal("close")}
              >
                Close
              </button>
              <button className="btn btn-primary" onClick={verifyOTP}>
                Verify
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <CustomModal show={show} body={Body} handleClose={handleClose} />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Loader>
  );
}

export default Login;
