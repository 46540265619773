import React, { useState, useEffect } from "react";
import { Pie, Bar, Doughnut } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  FaCity,
  FaLandmark,
  FaChartArea,
  FaHome,
  FaListUl,
  FaRegImages,
  FaIdCardAlt,
  FaBlogger,
} from "react-icons/fa";
import { TiThLargeOutline } from "react-icons/ti";
import { BiMoney, BiMerge } from "react-icons/bi";
import { GiCampingTent } from "react-icons/gi";
import {
  MdFeedback,
  MdEventAvailable,
  MdOutlineDeveloperBoard,
  MdOutlineAddHomeWork,
} from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { FcFaq } from "react-icons/fc";
import { BsPostcard } from "react-icons/bs";
import Header from "../../components/Header";

const Dashboard = () => {
  let navigate = useNavigate();

  const redirectTo = (path) => {
    return navigate("/admin/" + path);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  );

  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <div>
      <Container className="masters">
        <Row>
          <Col md={12} className="contBody">
            <Card>
              <CardBody>
                <div className="card__title pageNav">
                  <div className="bold-text themeColor pad-l-10">Dashboard</div>
                </div>
                <hr />

                <div class="card col-xs-5 col-sm-4 col-md-4">
                    <Doughnut data={data} />
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
