import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Button,
  DropdownButton,
  Form,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../../services/service";
import Loader from "../../../../components/Loader";
import empty from "is-empty";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import "../../.././common.css";

import CustomModal from "../../../../components/CustomModal";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { InputGroup } from "react-bootstrap";

const CustomerForm = () => {
  let navigate = useNavigate();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let location = useLocation();

  const authDetail = useSelector((state) => state.authDetail);
  const { error, details } = authDetail;
  console.log(details);

  let propsState = location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const [view, setView] = useState(false);

  const [editable, setEditable] = useState(false);

  const toggle = () => {
    setEditable(!editable);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  function validateInput(input, pattern) {
    return pattern.test(input);
  }

  const emailValidate = (e) => {
    console.log(e.target.value);
    const isValidEmail = validateInput(
      e.target.value,
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    );
    if (!isValidEmail) {
      console.log(e.target.value, "ullara");

      toast.error("Enter a valid EmailID", toastAttr);
      setForm({
        ...form,
        email: "",
      });
    } else {
      setForm({
        ...form,
        email: e.target.value,
      });
    }
  };

  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Customer",
      href: "/admin/masters/customer",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Customer Details",
      id: "details",
      show: true,
    },
  ]);

  const [country, setCountry] = useState([]);
  const [getcustomer, setGetCustomer] = useState([]);

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const [password, setPassword] = useState("");
  const [action, setAction] = useState("Show");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const handleInput = (keyName, value) => {
    console.log(keyName, value, "KJBVYGUVBI");

    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName == "country_id") {
      var country_code = "";
      country.map((cou) => {
        if (cou.id == value) {
          country_code = cou.country_code;
        }
      });
      setForm({
        ...form,
        [keyName]: value,
        country_code: country_code,
      });
    }
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    return navigate("/admin/masters/customer");
  };

  const findErrors = () => {
    const { email, mobile } = form;
    const newErrors = {};

    if (empty(email)) newErrors.email = "This is a required field";
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    return newErrors;
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    console.log(editMode, "mode");
    const newErrors = findErrors();
    console.log(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? service.customer + form.id + "/update/"
          : service.customer,
        data = JSON.parse(JSON.stringify(form));
      console.log("-----------");
      axios({
        url: url,
        method: method,
        headers: { Authorization: tokenKey },
        data: data,
      })
        .then((res) => {
          if (editMode)
            setForm({
              ...res.data,
              country_code: res.data.country_id.country_code,
            });
          toast.success("Saved successfully", toastAttr);
          setTimeout(navigate(-1), 1000);
          setLoader(false);

        })
        .catch((err) => {
          console.log("catchhhhhhhhhhhhhhhhh");
          // toast.error(err + " - No record found!", toastAttr);
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          console.log(err, "errrrrrrrrrrrrr");
          if (err?.response?.status === 500) {
            console.log(err.response.data);
          } else if (err?.response?.status === 400) {
            console.log(err.response.data.message);
          } else {
            console.log(err.response);
          }
        });
    }
  };



  const ShowPassword = () => {
    console.log("hello");
    if (action == "Hide") {
      setPassword("");
      setAction("Show");
    } else {
      axios({
        url: service.password_view,
        method: "post",
        data: { user_id: propsState.id },
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setPassword(res.data.decrypted_name);
          setAction("Hide");
        })
        .catch((err) => {
          console.log("catchhhhhhhhhhhhhhhhh");
          // toast.error(err + " - No record found!", toastAttr);
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          console.log(err, "errrrrrrrrrrrrr");
          if (err?.response?.status === 500) {
            console.log(err.response.data);
          } else if (err?.response?.status === 400) {
            console.log(err.response.data.message);
          } else {
            console.log(err.response);
          }
        });
    }
  };

  useEffect(() => {
    if (country.length == 0) {
      axios({
        url: service.country_list,
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setCountry(res.data.results);
        })
        .catch((err) => {
          console.log("catchhhhhhhhhhhhhhhhh");
          // toast.error(err + " - No record found!", toastAttr);
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          console.log(err, "errrrrrrrrrrrrr");
          if (err?.response?.status === 500) {
            console.log(err.response.data);
          } else if (err?.response?.status === 400) {
            console.log(err.response.data.message);
          } else {
            console.log(err.response);
          }
        });
    }

    console.log(propsState);
    if (propsState && propsState.editMode) {
      axios({
        url: service.customer + propsState.id + "/",
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setForm({
            ...res.data,
            country_code: res.data.country_id.country_code,
          });
          console.log(res);
          setLoader(false);
          console.log(form);
        })
        .catch((err) => {
          console.log("catchhhhhhhhhhhhhhhhh");
          // toast.error(err + " - No record found!", toastAttr);
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          console.log(err, "errrrrrrrrrrrrr");
          if (err?.response?.status === 500) {
            console.log(err.response.data);
          } else if (err?.response?.status === 400) {
            console.log(err.response.data.message);
          } else {
            console.log(err.response);
          }
        });
    }
  }, []);

  return (
    <Loader loading={loading} overlay={overlay}>
      <Container>
        <Row>
          <Col md={12}>
            <Card className="admin__list">
              <CardBody className="ms-3">
              <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Customer Details</h3>
                </Col>
                {/* <Col md={4}></Col>
                <Col md={2}></Col> */}
                {/* <Col > */}
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}>
                  
                </Col>
              </Row>
              <hr/>
                <Row className="mb-3 d-flex justify-content-center">
                  <Col md={10}>
                    <Button
                      variant="primary"
                      disabled={!editable}
                      onClick={(e) => {
                        saveForm();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={(e) => {
                        renderListView();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={2} hidden={!propsState?.editMode}>
                  <Button
                      className="ms-2 btn-grad-primary"
                      onClick={(e) => {
                        toggle();
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <div>
                      <Form>
                        {tab.details ? (
                          <>
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Email
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      className="input-border"
                                      placeholder="Enter Email"
                                      value={form.email}
                                      onChange={(e) =>
                                        handleInput("email", e.target.value)
                                      }
                                      isInvalid={!!errors.email}
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Mobile
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="text"
                                      name="mobile"
                                      className="input-border"
                                      placeholder="Enter Mobile Number"
                                      value={form.mobile}
                                      isInvalid={!!errors.mobile}
                                      onChange={(e) =>
                                        handleInput("mobile", e.target.value)
                                      }
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.mobile}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Country
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Select
                                      name="country_id"
                                      className="input-border"
                                      placeholder="select country"
                                      value={form.country_id?.id}
                                      disabled={!editable}
                                      onChange={(e) =>
                                        handleInput(
                                          "country_id",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.country_id}
                                    >
                                      {country.map((cou) => (
                                        <option
                                          key={cou.id}
                                          value={cou.id}
                                          className="dropdown"
                                        >
                                          {cou.country_name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.country_id}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Country Code
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      name="country_code"
                                      className="input-border"
                                      placeholder="Enter Country Code"
                                      value={form.country_code}
                                      onChange={(e) =>
                                        handleInput(
                                          "country_code",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.country_code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.country_code}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Is Active
                                    </Form.Label>
                                  </Col>
                                  <Col md={1}>
                                    <input
                                      type="checkbox"
                                      name="is_active"
                                      checked={form.is_active}
                                      onChange={(e) =>
                                        handleInput(
                                          "is_active",
                                          form.is_active == true ? false : true
                                        )
                                      }
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.is_active}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      User Type
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      name="user_type"
                                      className="input-border"
                                      placeholder="Enter Scheme ID"
                                      value={form.user_type}
                                      onChange={(e) =>
                                        handleInput("user_type", e.target.value)
                                      }
                                      isInvalid={!!errors.user_type}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.user_type}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {details.round ? (
                              <Row className="py-3">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        <Button onClick={ShowPassword}>
                                          {action} Password
                                        </Button>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7} className="mt-3 fw-bold">
                                      {password}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <CustomModal
                          show={show}
                          body={Body}
                          handleClose={handleClose}
                        />
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Loader>
  );
};

export default CustomerForm;
