import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import service from "../../../../services/service";
import axios from "axios";
import Loader from "../../../../components/Loader";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import empty from "is-empty";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GenerateSettlement = () => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState({});

  const [start_date, setStartdate] = useState();

  const [end_date, setEnddate] = useState();

  const dispatch = useDispatch();

  const authDetail = useSelector((state) => state.authDetail);
  const { loading: detailLoading, error, details } = authDetail;

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleInput = (keyName, value) => {
    console.log(keyName, value, "KJBVYGUVBI");

    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
    console.log(form, "....form---");
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const findErrors = () => {
    const { schemetype, monthdenomamt } = form;
    const newErrors = {};
    if (empty(schemetype)) newErrors.schemetype = "This is a required field";
    if (empty(monthdenomamt))
      newErrors.monthdenomamt = "This is a required field";

    return newErrors;
  };

  const onChangeDate = (value, keyName) => {
    let val = moment(value).format("YYYY-MM-DD");
    setForm({
      ...form,
      [keyName]: val,
    });
  };

  function validateInput(input, pattern) {
    return pattern.test(input);
  }

  const saveForm = () => {
    const newErrors = findErrors();

    setLoader(true);
    let data = JSON.parse(
      JSON.stringify({
        start_date: form.start_date,
        end_date: form.end_date,
      })
    );

    // API Call
    axios({
      url: service.generate_file,
      method: "post",
      headers: { Authorization: tokenKey },
      data: data,
    })
      .then((res) => {
        console.log(res.headers, "res");
        if (res.status === 200) {
          setLoader(false);
          const element = document.createElement("a");
          const file = new Blob([res.data], {
            type: "text/plain",
          });
          element.href = URL.createObjectURL(file);
          element.download = "KALYANJWLR_Settlement.txt";//res.headers['Content-Disposition'];
          document.body.appendChild(element);
          element.click();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    console.log(details);
  }, [details]);

  return (
    <div>
      <Container>
        <Form>
          <Card className="gift__card__create">
            <CardBody>
              <div className="text-uppercase fw-bold text-primary">
                Settlement File Generation
              </div>
              <hr />

              <Row className="py-2">
                <Col md={6} className="d-grid gap-3">
                  <Row>
                    <Col md={4}>
                      <Form.Label className="mt-2">Start Date</Form.Label>
                    </Col>
                    <Col md={7}>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          showIcon
                          name="start_date"
                          value={form.start_date}
                          onChange={(newValue) =>
                            onChangeDate(newValue, "start_date")
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="d-grid gap-3">
                  <Row>
                    <Col md={4}>
                      <Form.Label className="mt-2">End Date</Form.Label>
                    </Col>
                    <Col md={7}>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          showIcon
                          name="end_date"
                          value={form.end_date}
                          onChange={(newValue) =>
                            onChangeDate(newValue, "end_date")
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => {
                  saveForm();
                }}
              >
                Submit
              </Button>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </div>
  );
};

export default GenerateSettlement;
