import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
    authDetailsReducer, cookieReducer
} from './reducers/authReducer'

const reducer = combineReducers({
    
   
    authDetail : authDetailsReducer,
    clearCookie : cookieReducer,
    
})


// const userInfoFromStorage = sessionStorage.getItem('userInfo') ?
//     JSON.parse(sessionStorage.getItem('userInfo')) : null



// const initialState = {
   
//     userLogin: { userInfo: userInfoFromStorage },
// }

const middleware = [thunk]

const store = createStore(reducer,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store