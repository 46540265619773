import React from "react";

const Loader = ({
  type = "spinner-border",
  loading = true,
  children = null,
  overlay = false,
  text = true,
}) => {
  let divClass = overlay && children ? "overlayDiv" : "loaderDiv";
  return loading ? (
    <div className="loaderCont" style={{ zIndex: "9999" }} >
      <div className={divClass} style={{ background: "rgba(250, 250, 250, 0.7)" }}>
          <div
            className={type}
            style={{ color: "#d71a21", position: "absolute", top: "25%", bottom:"25%" }}
          />
          {text ? (
            <>
              <div className="loadingComp mt-5 fs-3">Loading...</div>
            </>
          ) : (
            ""
          )}
      </div>
      {overlay && children ? children : null}
    </div>
  ) : (
    children
  );
};

export default Loader;
