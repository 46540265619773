import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Modal, Row } from "react-bootstrap";

import { useNavigate, useLocation } from "react-router-dom";

import CloseButton from "react-bootstrap/CloseButton";
import service from "../services/service";

import "../pages/common.css";
import Select from "react-select";

import DataTable from "react-data-table-component";
import Loader from "./Loader";
import { AiFillBackward } from "react-icons/ai";
import { authDetails, clearAllCookies } from "../actions/authActions";

const customStyles = {
  headCells: {
    style: {
      textAlign: "center", // Center-align column names
      fontWeight: "bold",
      fontSize: "12px",
      // justifyContent: 'center',
      // paddingLeft: '0px', // override the cell padding for head cells
      // paddingRight: '0px',
    },
  },
  headCells: {
    style: {
      textAlign: "center", // Center-align column names
    },
  },
};

const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

const TermsAndCondition = () => {
  let navigate = useNavigate();

  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  const authDetail = useSelector((state) => state.authDetail);

  const [countryList, setCountryList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [termsLines, setTermLines] = useState([]);

  const [tableData, settableData] = useState([]);

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  let dispatch = useDispatch();

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(/=|;/);
      cookieValue = decodeURIComponent(cookies[1]);
    }
    return cookieValue;
  }
  var csrftoken = getCookie("csrf_token");
  var header = {};
  if (csrftoken != null) {
    header["X-CSRFToken"] = csrftoken;
  }

  var tableDataList = [
    // uae
    {
      country_code: 784,
      months: "6 Months",
      no_of_card_reload: "6 Times",
      gold: "40%",
      pup: "60%",
      diamond: "80%",
    },
    {
      country_code: 784,
      months: "9 Months",
      no_of_card_reload: "9 Times",
      gold: "60%",
      pup: "90%",
      diamond: "120%",
    },
    {
      country_code: 784,
      months: "12 Months",
      no_of_card_reload: "12 Times",
      gold: "100%",
      pup: "120%",
      diamond: "160%",
    },
    // qatar
    {
      country_code: 634,
      months: "6 Months",
      no_of_card_reload: "6 Times",
      gold: "40%",
      pup: "60%",
      diamond: "80%",
    },
    {
      country_code: 634,
      months: "9 Months",
      no_of_card_reload: "9 Times",
      gold: "60%",
      pup: "90%",
      diamond: "120%",
    },
    {
      country_code: 634,
      months: "12 Months",
      no_of_card_reload: "12 Times",
      gold: "100%",
      pup: "120%",
      diamond: "160%",
    },
    // kuwait
    {
      country_code: 414,
      months: "6 Months",
      no_of_card_reload: "6 Times",
      gold: "40%",
      pup: "60%",
      diamond: "80%",
    },
    {
      country_code: 414,
      months: "9 Months",
      no_of_card_reload: "9 Times",
      gold: "60%",
      pup: "90%",
      diamond: "120%",
    },
    {
      country_code: 414,
      months: "12 Months",
      no_of_card_reload: "12 Times",
      gold: "100%",
      pup: "120%",
      diamond: "160%",
    },
    // oman
    {
      country_code: 512,
      months: "6 Months",
      no_of_card_reload: "6 Times",
      gold: "40%",
      pup: "60%",
      diamond: "100%",
    },
    {
      country_code: 512,
      months: "9 Months",
      no_of_card_reload: "9 Times",
      gold: "60%",
      pup: "90%",
      diamond: "120%",
    },
    {
      country_code: 512,
      months: "12 Months",
      no_of_card_reload: "12 Times",
      gold: "100%",
      pup: "120%",
      diamond: "160%",
    },
  ];

  var header2 = null;

  const handleCountryList = () => {
    axios
      .get(service.country_details)
      .then((res) => {
        let countrylist = [];
        res.data.map((obj) => {
          countrylist.push({
            value: obj.id,
            label: obj.country_name,
            code: obj.country_code,
          });
        });

        console.log(countrylist, res.data);
        setCountryList(countrylist);
        // setLoader(false);
        console.log(authDetail, "PPPPPPPPPPPPPPPPPPPPPPPPPPPp");
        setSelectedCountry(countrylist[3]);
      })
      .catch((err) => {
        // if (err.response.status === 401) window.location.href = "/";
        console.log(err);
        // setLoader(false);
      });
  };

  const TermsAndConditionList = () => {
    let data = {};
    if (selectedCountry) {
      data.country_id = selectedCountry.value;
    }
        setLoader(true);

    axios({
      url: service.terms_and_condition_by_country,
      method: "post",
      headers: { ...header },
      data: data,
    })
      .then((res) => {
        setLoader(false);
        sessionStorage.setItem("tokenkey", res.data.token);
        dispatch(authDetails());
        console.log(res, ":nijinf");
        setTermLines(res.data.terms_and_condition_line);
      })
      .catch((err) => {
        setLoader(false);

        console.log(err, "nvoeioi");
      });

    let temp_list = [];
    tableDataList.map((i, index) => {
      if (i.country_code == selectedCountry?.code) {
        temp_list.push(i);
      }
    });
    settableData(temp_list);
  };

  useEffect(() => {
    if (countryList.length <= 0) {
      handleCountryList();
    }
    TermsAndConditionList();
  }, [selectedCountry]);

  const columns = [
    {
      id: 1,
      name: "MONTHS",
      selector: (row) => row.months,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "NUMBER OF CARD RELOADS",
      selector: (row) => row.no_of_card_reload,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 3,
      name: "GOLD",
      selector: (row) => row.gold,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "PRECIOUS/ UNCUT/POLKI",
      selector: (row) => row.pup,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      id: 5,
      name: "DIAMOND",
      selector: (row) => row.diamond,
      sortable: true,
      // right: true,
      reorder: true,
    },
  ];
  return (
    <Loader loading={loading} overlay={overlay}>

    <Row className="background">
      <Row className="d-flex justify-content-center mt-3">
        <img src={kalyan_logo} className="kalyan__logo" alt="kalyan_logo" />
      </Row>

      <Row className="d-flex justify-content-end mt-3">
        {/* <Col md={1}>
          <Button className="d-flex ms-4" variant="primary" onClick={()=>navigate('/')}>
          <AiFillBackward className="me-2 " size={20} />Back
          </Button>
        </Col> */}
        <Col md={3}>
          <Select
            onChange={setSelectedCountry}
            value={selectedCountry}
            options={countryList}
            placeholder="Select Country"
            isSearchable={false}
          />
        </Col>
      </Row>
      <Row>
        {termsLines.reverse().map((ref, indef) => {
          if (ref.content_type == "heading" && Number(ref.sequence) > 0) {
            header2 = Number(ref.sequence);
            return null;
          }
          if (header2 && indef >= header2) {
            console.log(header2, "lopolop");
            return null;
          }
          return (
            <Row className="ms-3">
              {ref.content_type == "heading" ? (
                <span className="fw-bold mb-3">{ref.text_content}</span>
              ) : (
                <ul className="ms-5">
                  <li>{ref.text_content}</li>
                </ul>
              )}
            </Row>
          );
        })}
      </Row>
      <Row className="fw-bold d-flex justify-content-center fs-4 mt-2">
        GIFT CARD RELOAD PROGRAM
      </Row>
      <Row className="fw-bold text-muted d-flex justify-content-center fs-5 mt-2">
        DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS FOLLOWS (G.C.R.P)
      </Row>
      <Row className="d-flex justify-content-center mt-3 mb-5">
        <Col md={10}>
          <DataTable
            className=""
            columns={columns}
            customStyles={customStyles}
            data={tableData}
          />
        </Col>
      </Row>
      <Row>
        {termsLines.map((ref, indef) => {
          // if(ref.content_type == "heading" && Number(ref.sequence) > 0){
          //   header2 = Number(ref.sequence)
          //   return null
          // }
          if (header2 && indef < header2) {
            console.log(header2, "lopolop");
            return null;
          }
          return (
            <Row className="ms-3">
              {ref.content_type == "heading" ? (
                <span className="fw-bold mb-3">{ref.text_content}</span>
              ) : (
                <ul className="ms-5">
                  <li>{ref.text_content}</li>
                </ul>
              )}
            </Row>
          );
        })}
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={1}>
          <Button
            className="d-flex ms-4"
            variant="primary"
            onClick={() => navigate(-1)}
          >
            <AiFillBackward className="me-2 " size={20} />
            Back
          </Button>
        </Col>
      </Row>
    </Row>
    </Loader>
  );
};

export default TermsAndCondition;
