import React, { useState, useEffect } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  FaCity,
  FaLandmark,
  FaChartArea,
  FaHome,
  FaListUl,
  FaRegImages,
  FaIdCardAlt,
  FaBlogger,
} from "react-icons/fa";
import { TiThLargeOutline } from "react-icons/ti";
import { BiMoney, BiMerge } from "react-icons/bi";
import { GiCampingTent } from "react-icons/gi";
import {
  MdFeedback,
  MdEventAvailable,
  MdOutlineDeveloperBoard,
  MdOutlineAddHomeWork,
} from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { FcFaq } from "react-icons/fc";
import { BsPostcard } from "react-icons/bs";

const Log = () => {
  let navigate = useNavigate();

  const redirectTo = (path) => {
    return navigate("/admin/logs/" + path);
  };

  return (
    <div>
      <Container className="masters">
        <Row>
          <Col md={12} className="contBody">
            <Card>
              <CardBody>
                <div className="card__title pageNav">
                  <div
                    className="bold-text themeColor pad-l-10"
                    onClick={() => navigate("/admin/dashboard")}
                  >
                    Dashboard
                  </div>
                  
                </div>
                <hr />
                <div className="card__title pageNav">
                  <div className="bold-text themeColor pad-l-10">Log</div>
                </div>

                

                <Row style={{ padding: "0 20px 10px 0" }}>
                  <Col md={12}>
                    <Row>
                      <Col md={2} className="m_col">
                        <div className="m_icon">
                          <FaLandmark
                            style={{ color: "#00B0FC" }}
                            onClick={(e) => redirectTo("apilog")}
                          />
                          <div>API Log</div>
                        </div>
                      </Col>

                      <Col md={2} className="m_col">
                        <div className="m_icon">
                          <FaLandmark
                            style={{ color: "#00B0FC" }}
                            onClick={(e) => redirectTo("equalsapilog")}
                          />
                          <div>Equals API Log</div>
                        </div>
                      </Col>

                      <Col md={2} className="m_col">
                        <div className="m_icon">
                          <FaLandmark
                            style={{ color: "#00B0FC" }}
                            onClick={(e) => redirectTo("sicollectionlog")}
                          />
                          <div>SI Collection Log</div>
                        </div>
                      </Col>
                      <Col md={2} className="m_col">
                        <div className="m_icon">
                          <FaLandmark
                            style={{ color: "#00B0FC" }}
                            onClick={(e) => redirectTo("paymentlog")}
                          />
                          <div>Payment Transaction Log</div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Log;
