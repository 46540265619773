import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import "./ResponsePage.css";

const ResponseBilldesk = () => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;
  console.log("gitftcard topup");

  let location = useLocation();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  // Custom const
  const [cardid, setCardid] = useState([]);
  const [paystatus, setPaystatus] = useState("");
  const [account_no, setCardnumber] = useState("");
  const [referencenumber, setReferencenumber] = useState("");
  let dispatch = useDispatch();

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [tab] = useState({
    details: true,
  });

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  // Load Default data by calling GiftCardTopup
  useEffect(() => {
    setLoader(true);

    // Read Params from URL - Start
    const searchParams = new URLSearchParams(document.location.search);
    const params = {};
    for (const key of searchParams.keys()) {
      params[key] = searchParams.get(key);
    }
    console.log(params, "params");
    // Read Params from URL - End

    let account_no = params["account_no"] ? params["account_no"] : "";
    let referencenumber = params["reciept"] ? params["reciept"] : "";
    let pay_status = params["status"] ? params["status"] : "";
    setCardnumber(account_no);
    setReferencenumber(referencenumber);
    setPaystatus(pay_status);
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="ms-3">
                <Row>
                  <Col md={12}>
                    {}

                    <div>
                      <div class="check" id="success-mark">
                        <i
                          class="fa fa-check text-success"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="check" id="fail-mark">
                        <i
                          class="fa fa-times text-danger"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="content">
                      {paystatus == "Payment Success" ? (
                        <div class="svg-box">
                          <svg class="circular green-stroke">
                            <circle
                              class="path"
                              cx="75"
                              cy="75"
                              r="50"
                              fill="none"
                              stroke-width="5"
                              stroke-miterlimit="10"
                            />
                          </svg>
                          <svg class="checkmark green-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                              <path
                                class="checkmark__check"
                                fill="none"
                                d="M616.306,283.025L634.087,300.805L673.361,261.53"
                              />
                            </g>
                          </svg>
                        </div>
                      ) : (
                        <div class="svg-box">
                          <svg class="circular red-stroke">
                            <circle
                              class="path"
                              cx="75"
                              cy="75"
                              r="50"
                              fill="none"
                              stroke-width="5"
                              stroke-miterlimit="10"
                            />
                          </svg>
                          <svg class="cross red-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                              <path
                                class="first-line"
                                d="M634.087,300.805L673.361,261.53"
                                fill="none"
                              />
                            </g>
                            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                              <path
                                class="second-line"
                                d="M634.087,300.805L673.361,261.53"
                              />
                            </g>
                          </svg>
                        </div>
                      )}

                      <h1>{paystatus}</h1>
                      <p>
                        Please find below your account number and payment
                        reference number.{" "}
                      </p>
                      <p>
                        <b>Account No. : </b>
                        <span>{account_no}</span>
                      </p>
                      <p>
                        <b>Reference Number : </b>
                        <span>{referencenumber}</span>
                      </p>
                      {/* <p *ngIf="si_status=='yes' && paymentstatus=='success'"><b>Successfully Registered for Monthly Auto debit from credit card</b></p> */}
                      <a href="/schemeregistered" class="primary">
                        Go to Home
                      </a>
                    </div>
                  </Col>
                </Row>

                <hr />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResponseBilldesk;
