import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  CardHeader,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import service from "../../../../services/service";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Loader from "../../../../components/Loader";
import CustomModal from "../../../../components/CustomModal";

const Customer = () => {
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");
  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let navigate = useNavigate();
  let location = useLocation();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Customer",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [pending, setPending] = useState(true);
  const [sorting, setSorting] = useState("");

  // const [dateRange, setdateRange] = useState({
  //   start_date: null,
  //   end_date: null,
  // });
  // const [dateLabel, setdateLabel] = useState("");

  const [countryList, setCountryList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [active, setActive] = useState(true);

  const deleteRow = (row) => {
    console.log("delete", row.id);
  };

  const renderForm = (row) => {
    if (row)
      return navigate({
        pathname: "/admin/masters/customerForm",

        state: "",
      });
    else
      return navigate({
        pathname: "/admin/masters/customerForm",
        state: {},
      });
  };

  const handleSort = async (column, sortDirection) => {
    console.log(column, sortDirection);
    if (sortDirection == "asc") {
      setSorting(column.sorting_name);
    } else {
      setSorting("-" + column.sorting_name);
    }
  };

  const customerList = () => {
    console.log(selectedCountry, "jferfib");
    let data = !active
      ? { is_email_verified: isEmailVerified }
      : { all_customer: active };
    if (selectedCountry) {
      data.country_id = selectedCountry.value;
    }
    if (search.length > 0) {
      data.search = search;
    }
    setLoader(true);

    axios({
      url: service.customer_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: { ...data, ordering: sorting, page: page, size: perPage },
    })
      .then((res) => {
        settableData(res.data.results);
        console.log(res);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader(false);

        if (err.response?.status === 401) {
          window.location.href = "/";
        }
        console.log(err, "errrrrrrrrrrrrr");
        if (err.response?.status === 500) {
          setShow(true);
          setBody(err.response.data);
        } else if (err.response?.status === 400) {
          setShow(true);
          setBody(JSON.stringify(err.response.data));
        } else {
          setShow(true);
          setBody(err?.message);
        }
      });
  };

  const handleCountryList = () => {
    setLoader(true);

    axios
      .get(service.country_details)
      .then((res) => {
        let countrylist = [];
        res.data.map((obj) => {
          countrylist.push({
            value: obj.id,
            label: obj.country_name,
          });
        });

        console.log(countrylist, res.data);
        setCountryList(countrylist);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    customerList();
    if (countryList.length <= 0) {
      handleCountryList();
    }
  }, [search, page, perPage, selectedCountry, isEmailVerified, active,sorting]);

  const handlePageChange = async(page) => {
    // setPage(page);
console.log(perPage,"//",page);
    await axios({
      url: service.customer_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: {
        page: page,
        size: perPage,
        search: search,
        country_id: selectedCountry && selectedCountry.value,
        ordering: sorting,
      },
    })
      .then((res) => {
        settableData(res.data.results);
        setTotalRows(res.data.count);
        setPending(false);
        console.log(res);
        setLoader(false)

      })
      .catch((err) => {
        if(err.response.status==400){
          setShow(true);
          setBody(err.response.data.message);
        }else if(err.response.status==500){
          setShow(true);
          setBody(err.response.data);
        }else if(err.response.status==401){
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader(false);

      });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log(newPerPage,page)
    setLoader(true)

    await axios({
      url: service.customer_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: {
        page: page,
        size: newPerPage,
        search: search,
        country_id: selectedCountry && selectedCountry.value,
        ordering: sorting,
      },
    })
      .then((res) => {
        settableData(res.data.results);
        setPerPage(newPerPage);
        setPending(false);
        console.log(res);
        setLoader(false)

      })
      .catch((err) => {
        if(err.response.status==400){
          setShow(true);
          setBody(err.response.data.message);
        }else if(err.response.status==500){
          setShow(true);
          setBody(err.response.data);
        }else if(err.response.status==401){
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader(false);

      });
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "20px",
        justifyContent: "flex-start",

        // paddingLeft: '0px', // override the cell padding for head cells
        // paddingRight: '0px',
      },
    },
    rows: {
      style: {
        fontSize: "18px",
        minHeight: "52px",
        justifyContent: "flex-start",
      },
    },
    // headCells: {
    //   style: {
    //     textAlign: 'center', // Center-align column names
    //     fontSize: '50px',

    //   },
    // },
  };

  const columns = [
    {
      id: 1,
      name: "Email ID",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      width: "20%",
      sorting_name: "email",
    },
    {
      id: 2,
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      // right: true,
      reorder: true,
      sorting_name: "mobile",
    },
    {
      id: 3,
      name: "Country Code",
      selector: (row) => (row.country_id ? row.country_id.country_code : null),
      sortable: true,
      // right: true,
      reorder: true,
      sorting_name: "country_id",
    },
    {
      id: 4,
      name: "Country",
      selector: (row) => (row.country_id ? row.country_id.country_name : null),
      sortable: true,
      // right: true,
      reorder: true,
      sorting_name: "country_id",
    },
    {
      id: 5,
      name: "Is Email Verified",
      width: "20%",
      cell: (row) => (
        <>
          <input type="checkbox" readOnly checked={row.is_email_verified} />
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sorting_name: "is_email_verified",
    },

    {
      id: 6,
      name: "Actions",

      cell: (row) => (
        <>
          <span
            onClick={() => {
              console.log(row.id);
              return navigate("/admin/masters/customerForm", {
                state: {
                  id: row.id,
                  editMode: true,
                },
              });
            }}
            className="btn btn-grad-primary"
          >
            View
          </span>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    // <Container>

    <div className="px-5">
      <div>
        <div className="pageNav">
          <div className="bold-text">
            {/* <Breadcrumbs data={breadcrumbData} /> */}
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <Card className="admin__list">
            <>
            <Row>
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Customer</h3>
                </Col>
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}></Col>
              </Row>
              <hr />
              <Row className="d-flex   justify-content-between">
                <Col md={3}>
                  <div className="mx-3">
                    <Select
                      onChange={setSelectedCountry}
                      value={selectedCountry}
                      options={countryList}
                      placeholder="Select Country"
                      isSearchable={false}
                      isClearable={true}
                    />
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col
                  md={3}
                  className="d-flex flex-row justify-content-between mt-2"
                >
                  <Label className="fw-bold" name="email_verify">
                    Is Email Verified
                  </Label>
                  <Col sm={3}>
                    <input
                      type="radio"
                      id="yes"
                      className=""
                      name="email_verify"
                      checked={isEmailVerified}
                      disabled={active}
                      onClick={() => setIsEmailVerified(!isEmailVerified)}
                    />
                    <Label
                      className="fw-bold ms-2"
                      name="email_verify"
                      for="yes"
                    >
                      Yes
                    </Label>
                  </Col>
                  <Col md={3}>
                    <input
                      type="radio"
                      id="no"
                      className=""
                      name="email_verify"
                      checked={!isEmailVerified}
                      disabled={active}
                      onClick={() => setIsEmailVerified(!isEmailVerified)}
                    />
                    <Label
                      className="fw-bold ms-2"
                      name="email_verify"
                      for="no"
                    >
                      No
                    </Label>
                  </Col>
                  <Col md={3}>
                    <input
                      type="checkbox"
                      id="all"
                      className=""
                      name="active"
                      checked={active}
                      onClick={() => setActive(!active)}
                    />
                    <Label className="fw-bold ms-2" name="active" for="all">
                      all
                    </Label>
                  </Col>
                </Col>
              </Row>
            </>
            <hr/>
            <CardBody>
              {/* <div className="pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div> */}

              <Row className="d-flex justify-content-between align-content-center formBtnsDiv mar-b-10 mt-3 mb-3">
                <Col md={4}>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      style={{ width: "80%" }}
                    />
                  </div>
                </Col>
                <Col md={6}></Col>
                <Col md={2}>
                  <InputGroup className="mb-3 w-100">
                    <Form.Control
                      placeholder="Go to page"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      id="searchPage"
                    />
                    <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={() => {
                        setPage(document.getElementById("searchPage").value);
                      }}
                    >
                      go
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Loader loading={loading} overlay={overlay}>
                <Row>
                  <DataTable
                    columns={columns}
                    data={tableData}
                    defaultSortFieldId={1}
                    pagination
                    paginationServer
                    customStyles={customStyles}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSort={handleSort}
                    sortServer
                  />
                </Row>
              </Loader>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomModal show={show} body={Body} handleClose={handleClose} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    // </Container>
  );
};

export default Customer;
