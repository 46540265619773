import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Button,
  DropdownButton,
  Form,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../../services/service";
import Loader from "../../../../components/Loader";
import empty from "is-empty";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import "../../.././common.css";
import CustomModal from "../../../../components/CustomModal";

const PaymentLogForm = () => {
  let navigate = useNavigate();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;


  let location = useLocation();

  let propsState = location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Log",
      href: "/admin/logs",
    },
    {
      title: "Payment Log",
      href: "/admin/logs/paymentlog",
    },
    {
      title: 'View',
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Payment Log Details",
      id: "details",
      show: true,
    },
  ]);

  const [user, setUser] = useState([]);
  const [getcustomer, setGetCustomer] = useState([]);
  
  const handleInput = (keyName, value) => {
    console.log(keyName,value,"KJBVYGUVBI")
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    return navigate("/admin/masters/customer");
  };

  const findErrors = () => {
    const { scheme_id, scheme_name, country_id, min_amount, max_amount } = form;
    const newErrors = {};

    if (empty(scheme_id)) newErrors.scheme_id = "This is a required field";
    if (empty(scheme_name)) newErrors.scheme_name = "This is a required field";
    if (empty(country_id)) newErrors.country_id = "This is a required field";
    if (empty(min_amount)) newErrors.min_amount = "This is a required field";
    if (empty(max_amount)) newErrors.max_amount = "This is a required field";
    return newErrors;
  };

 
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    if (user.length == 0) {
      axios({
        url: service.customer_list,
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setUser(res.data.results);
        })
        .catch((err) => {
          if(err.response.status==400){
            setShow(true);
            setBody(err.response.data.message);
          }else if(err.response.status==500){
            setShow(true);
            setBody(err.response.data);
          }else if(err.response.status==401){
            setShow(true);
            setBody(err.response.data.detail);
          }
        });
    }

    console.log(propsState);
    if (propsState && propsState.editMode) {
      setLoader(true);

      axios({
        url: service.payment_log+propsState.id+"/",
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setForm(res.data);
          console.log(res);
          setLoader(false);
          console.log(form);
        })
        .catch((err) => {
          if(err.response.status==400){
            setShow(true);
            setBody(err.response.data.message);
          }else if(err.response.status==500){
            setShow(true);
            setBody(err.response.data);
          }else if(err.response.status==401){
            setShow(true);
            setBody(err.response.data.detail);
          }
        setLoader(false);

        });
      }
  }, []);


  return (
    <Loader loading={loading} overlay={overlay}>

    <Container>
      <Row >
        <Col md={12}>
          <Card>
            <CardBody className="ms-3">
            <Row className="mb-3">
              <div className="pageNav col-lg-5 mt-2">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <div className="col-lg-5 fs-4">
                Payment Log Details
              </div>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <div>
                    <Form>
                      
                        {tab.details ? (
                          <>
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Topup ID
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="topup_id"
                                      className="input-border"
                                      value={form.topup_id}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Payment Gateway
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="payment_gateway"
                                      className="input-border"
                                      value={form.payment_gateway}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Amount
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="amount"
                                      className="input-border"
                                      value={form.amount}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                    EMI Month
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="emi_month"
                                      className="input-border"
                                      value={form.emi_month}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Merchant Order No.
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="merchant_order_no"
                                      className="input-border"
                                      value={form.merchant_order_no}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Mobile No.
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control
                                      readOnly
                                      type="text"
                                      name="mobile_no"
                                      className="input-border"
                                      value={form.mobile_no}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Payment Mode
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="payment_mode"
                                      className="input-border"
                                      value={form.payment_mode}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Status
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      type="text"
                                      name="status"
                                      className="input-border"
                                      value={form.status}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Request Time
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      name="request_time"
                                      className="input-border"
                                      value={form.request_time}
                                    >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.request_time}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Response Time
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      readOnly
                                      type="text"
                                      name="response_time"
                                      className="input-border"
                                      value={form.response_time}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.response_time}
                                      </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Created By
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Select
                                      disabled
                                      name="created_by_id"
                                      className="input-border"
                                      value={form.created_by_id?.id}
                                      onChange={(e) =>
                                        handleInput(
                                          "created_by_id",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.created_by_id}
                                    >
                                        {user.map((user1) => (
                                          <option
                                            key={user1.id}
                                            value={user1.id}
                                            className="dropdown"
                                          >
                                            {user1.email}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Updated By
                                    </Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Select
                                      disabled
                                      name="updated_by_id"
                                      className="input-border"
                                      value={form.updated_by_id?.id}
                                      onChange={(e) =>
                                        handleInput(
                                          "updated_by_id",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.updated_by_id}
                                    >
                                        {user.map((user1) => (
                                          <option
                                            key={user1.id}
                                            value={user1.id}
                                            className="dropdown"
                                          >
                                            {user1.email}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="py-3">
                              <Col md={12} className="d-grid gap-3">
                                <Row>
                                  <Col md={2}>
                                    <Form.Label className="mt-2">
                                      Request
                                    </Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <Form.Control
                                      readOnly
                                      as="textarea"
                                      name="request_string"
                                      className="input-border"
                                      value={form.request_string}
                                      style={{height:'190px'}}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>


                            <Row className="py-3">

                              <Col md={12} className="d-grid gap-3">
                                <Row>
                                  <Col md={2}>
                                    <Form.Label className="mt-2">
                                      Response
                                    </Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <Form.Control
                                      readOnly
                                      as="textarea"
                                      name="response_string"
                                      className="input-border"
                                      value={form.response_string}
                                      style={{height:'190px'}}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>



                          </>
                        ) : (
                          ""
                        )}
                     <CustomModal show={show} body={Body} handleClose={handleClose} />
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </Loader>
    
  );
};

export default PaymentLogForm;
