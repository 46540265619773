import { AUTH_SESSION_LIST_FAIL, AUTH_SESSION_LIST_REQUEST, AUTH_SESSION_LIST_SUCCESS, CLEAR_ALL_COOKIES } from "../constants/authConstants";

export const authDetailsReducer = (state = { details: [] }, action) => {
    switch (action.type) {
      case AUTH_SESSION_LIST_REQUEST:
        return { loading: true, details: [] };
  
      case AUTH_SESSION_LIST_SUCCESS:
        return {
          loading: false,
          details: action.payload,
        };
      case AUTH_SESSION_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };

export function cookieReducer(state={}, action) {
  switch (action.type) {
    case CLEAR_ALL_COOKIES:
      return {
        ...state,
      };
    default:
      return state;
  }
}


    