import DateRangePicker from "react-bootstrap-daterangepicker";

const DateRangePickerWrapper = (props) => {
  return (
    <DateRangePicker
      {...props}
      onShow={(event, picker) => {
        picker.container.addClass("show-in");
      }}
      onHide={(event, picker) => {
        picker.container.removeClass("show-in");
      }}

    />
  );
};

export default DateRangePickerWrapper;
