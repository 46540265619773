import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Modal, Row } from "react-bootstrap";

import CloseButton from "react-bootstrap/CloseButton";
import service from "../services/service";

const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

const useMatchMedia = (mediaQuery, initialValue) => {
  const [isMatching, setIsMatching] = useState(initialValue);
  useEffect(() => {
    const watcher = window.matchMedia(mediaQuery);
    setIsMatching(watcher.matches);
    const listener = (matches) => {
      setIsMatching(matches.matches);
    };
    if (watcher.addEventListener) {
      watcher.addEventListener("change", listener);
    } else {
      watcher.addListener(listener);
    }
    return () => {
      if (watcher.removeEventListener) {
        return watcher.removeEventListener("change", listener);
      } else {
        return watcher.removeListener(listener);
      }
    };
  }, [mediaQuery]);
  return isMatching;
};
const ProfileView = (props) => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  const authDetail = useSelector((state) => state.authDetail);

  const isDesktopResolution = useMatchMedia("(max-width:995px)", false);

  // const display = {
  //   display: "none",
  // };

  const [personalDetail, setPersonalDetail] = useState({});
  const [currentAddress, setCurrentAddress] = useState({});
  const [permanentAddress, setPermanentAddress] = useState({});
  const [dob, setDOB] = useState("");

  useEffect(() => {
    if (props.show) {
      axios({
        url: service.getcustomerinfo,
        method: "post",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          console.log(res.data, "res.data");
          setPersonalDetail(res.data.personalDetails);
          console.log(personalDetail, "personalDetail");
          setCurrentAddress(res.data.currentAddress);
          setPermanentAddress(res.data.permanentAddress);
          setDOB(res.data.DOB);
        })
        .catch((err) => {});
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
      >
        <Modal.Header className="modalotpheader">
          <div className="d-flex justify-content-between">
            <span className="fw-bold fs-5">Customer Profile</span>
          </div>

          <CloseButton
            variant="white"
            onClick={(e) => props.handleClose(false)}
          />
        </Modal.Header>
        <Modal.Body className=" p-0 mx-2 px-1 ">
          <Row
            className="pe-5"
            style={{ height: "550px", overflow: "scroll", overflowX: "hidden" }}
          >
            {/* <div className="bg-secondary position-relative col-md-3" style={{display:isDesktopResolution ? 'none' : 'flex'}}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="d-flex flex-column">
                  <img
                    src={kalyan_logo}
                    className="kalyan__logo ms-2"
                    alt="kalyan_logo"
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <label className="d-flex justify-content-center text-white mt-4 fs-3">
                      Customer
                    </label>
                    <label className="d-flex justify-content-center text-white fs-4">
                      Profile
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
            <Card className=" ms-4 me-5 ms-3 my-4">
              <Col>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-1">
                  PERSONAL INFORMATION
                </Row>
                <hr className="mt-1" />

                <Row className="mt-3 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        First Name
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail?.firstName
                          ? personalDetail?.firstName
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Last Name
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail?.lastName &&
                        personalDetail.lastName !== "undefined"
                          ? personalDetail.lastName
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Mobile
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail?.mobileNumber
                          ? personalDetail?.mobileNumber
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Email
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail?.emailAddress
                          ? personalDetail.emailAddress
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        DOB
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {dob &&
                          dob.slice(8, 10) +
                            "/" +
                            dob.slice(5, 7) +
                            "/" +
                            dob.slice(0, 4)}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="text-danger fw-bold fs-5 ms-1 mt-3">
                  CURRENT ADDRESS
                </Row>
                <hr className="mt-1" />
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 1
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress?.street1 ? currentAddress?.street1 : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 2
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress?.street2 ? currentAddress.street2 : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        City
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress?.city ? currentAddress?.city : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        State
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress?.state ? currentAddress.state : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        PO Box
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress?.postOffice
                          ? currentAddress?.postOffice
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Country
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.Country}
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-3">
                  PERMANENT ADDRESS
                </Row>
                <hr className="mt-1" />
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 1
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress?.street1
                          ? permanentAddress?.street1
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 2
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress?.street2
                          ? permanentAddress?.street2
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        City
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress?.city ? permanentAddress?.city : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        State
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress?.state ? permanentAddress?.state : ""}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        PO Box
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress?.postOffice
                          ? permanentAddress?.postOffice
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Country
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.Country}
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-3 d-flex justify-content-end p-2">
                  <span
                    className="btn btn-grad-primary"
                    onClick={() => props.logoutHandler()}
                  >
                    Logout
                  </span>
                </Row>
              </Col>
            </Card>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileView;
