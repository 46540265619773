import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as RiIcons from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "is-empty";
import {
  faIdCard,
  faCog,
  faClipboardList,
  faList,
  faFileCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #d71a21;
    color: white;
    border-left: 4px solid grey;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: white;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 18px;

  &:hover {
    background: #d71a21;
    color: white;
    cursor: pointer;
  }
`;

const SubMenu = ({ item,handleSidebarClose }) => {

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  const DynamicIcon = ({ item }) => {
    console.log(item.icon);
    switch (item.icon) {
      case "faCog":
        return <FontAwesomeIcon icon={faCog} />;
      case "faList":
        return <FontAwesomeIcon icon={faList} />;
      case "faIdCard":
        return <FontAwesomeIcon icon={faIdCard} />;
      case "faClipboardList":
        return <FontAwesomeIcon icon={faClipboardList} />;
      case "faFileCircleCheck":
        return <FontAwesomeIcon icon={faFileCircleCheck} />;

    }

  };

  return (
    <>
      {/* <SidebarLink to={item.url_value} > */}
      <SidebarLink to={`${item.url_value ? item.url_value : '#'}`} onClick={!isEmpty(item.submenu) ? showSubnav : (e)=>handleSidebarClose(false)}>
        <div>
          <DynamicIcon item={item} />
          <SidebarLabel>{item.menu}</SidebarLabel>
        </div>
        <div>
          {!isEmpty(item.submenu) && subnav ? (
            <RiIcons.RiArrowUpSFill />
          ) : !isEmpty(item.submenu) ? (
            <RiIcons.RiArrowDownSFill />
          ) : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.submenu.map((item, index) => {
          return (
            <DropdownLink to={item.url_value} key={index} onClick={(e)=>handleSidebarClose(false)}>
              <div>
                <DynamicIcon item={item} />
                <SidebarLabel>{item.name}</SidebarLabel>
              </div>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
