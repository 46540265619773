import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CustomModal(props) {
  console.log(props);

  return (
    <>
    {props.body?.length>100 && <Modal size="xl" show={props.show} onHide={(e) => props.handleClose(false)}>
        
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div style={{ padding:"10%" }}>{props.body}</div>
          <div>
            <Button variant="primary" onClick={(e) => props.handleClose(false)}>
              Ok
            </Button>
          </div>
        </Modal.Body>
        
      </Modal>}
      {props.body?.length<100 && props.body.length>50 && <Modal size="lg" show={props.show} onHide={(e) => props.handleClose(false)}>
        
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div style={{ padding:"10%" }}>{props.body}</div>
          <div>
            <Button variant="primary" onClick={(e) => props.handleClose(false)}>
              Ok
            </Button>
          </div>
        </Modal.Body>
        
      </Modal>}
      {props.body?.length<50 && <Modal size="xs" show={props.show} onHide={(e) => props.handleClose(false)}>
        
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div style={{ padding:"10%" }}>{props.body}</div>
          <div>
            <Button variant="primary" onClick={(e) => props.handleClose(false)}>
              Ok
            </Button>
          </div>
        </Modal.Body>
        
      </Modal>}
    </>
  );
}

export default CustomModal;
