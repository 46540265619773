import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Form } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import empty from "is-empty";

const ProceedRazorpay = () => {
  console.log("ProceedPayment topup");

  let location = useLocation();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [submiturl, submitUrl] = useState("");

  const [tab] = useState({
    details: true,
  });

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    const { msg } = form;
    const newErrors = {};

    if (empty(msg)) newErrors.EnrollmentNo = "This is a required field";
    return newErrors;
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const handleSubmit = () => {
    let form = document.querySelector("#cc_avenue");
    form.submit()
    // HTMLFormElement.prototype.submit.call(form);
  };

  // Default data from previous Page (GiftCardTopup)
  useEffect(() => {
    setLoader(true);
    console.log(location.state.returndata);
    const { options } = location.state.returndata.msg;
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => {
      console.log('Razorpay', window.Razorpay);
      console.log(options);

      const rzp1 = new window.Razorpay(JSON.parse(location.state.returndata.msg));
      rzp1.open();
    };

    document.body.appendChild(script);

    return () => {
      // Clean up code if needed
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Loader loading={loading} overlay={overlay}>
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="ms-3">
                <Row className="mb-3">
                  <div className="col-lg-5 fs-4" style={{ display: 'none' }}>Gateway Redrect</div>
                  <p>Please wait while your transaction is being processed. You will be automatically redirected to the
              confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Loader>
  );
};

export default ProceedRazorpay;
